<template>
  <div>
    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Haetaan vuokralaista..."
      class="full-page-loader"
    ></full-page-loader>

    <!-- FORM -->
    <v-form v-if="!loading" ref="form">
      <v-card class="mt-2">
        <v-card-title
          ><div>Perustiedot</div>
          <v-spacer></v-spacer>
          <router-link :to="`/tenant/overview/${$route.params.id}`">
            <v-btn color="primary">Yleisnäkymään</v-btn>
          </router-link>
        </v-card-title>

        <v-container>
          <v-row dense>
            <v-col cols="6" md="4" lg="3" xl="2">
              <v-radio-group v-model="currentTenant.isProspect" :mandatory="true">
                <v-radio label="Potentiaalinen vuokralainen" :value="true"></v-radio>
                <v-radio label="Vuokralainen" :value="false"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="6" md="4" lg="3" xl="2">
              <v-radio-group
                v-model="currentTenant.isCompany"
                :mandatory="true"
                @change="setBasicFields"
              >
                <v-radio label="Yksityinen" :value="false"></v-radio>
                <v-radio label="Yritys" :value="true"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="6" md="4" lg="3" xl="2">
              <v-radio-group v-model="currentTenant.vatRegister" :mandatory="true">
                <v-radio label="Alv-rekisterissä" :value="true"></v-radio>
                <v-radio label="Ei Alv-rekisterissä" :value="false"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="6" md="4" lg="3" xl="2">
              <v-radio-group v-model="currentTenant.creditReport" :mandatory="true">
                <v-radio label="Luottotiedot ei tarkistettu" value="notChecked"></v-radio>
                <v-radio label="Luottotiedot ok" value="passed"></v-radio>
                <v-radio label="Maksuhäiriö" value="paymentFailure"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col
              v-if="!currentTenant.isCompany"
              cols="6"
              md="4"
              lg="3"
              xl="2"
              title="Laskutusohjelmat soveltavat erilaisia käytäntöjä saatavien perintään kansalaisuuteen perustuen."
            >
              <v-checkbox
                v-model="currentTenant.finnishCitizen"
                :label="`Vuokralaisella on suomalainen henkilötunnus`"
              ></v-checkbox>
            </v-col>

            <v-col cols="6" md="4" lg="3" xl="2">
              <v-select
                v-model="currentTenant.selectedTenantType"
                label="Vuokralaisen tyyppi"
                outlined
                dense
                item-value="val"
                item-text="text"
                :items="[
                  { text: 'Vuokralainen', val: 'tenant' },
                  { text: 'Avaimenhaltija', val: 'keyHolder' },
                ]"
              ></v-select>
            </v-col>
          </v-row>

          <v-row dense v-if="isTalenomService">
            <v-col cols="12">
              <div>
                <strong>Talenom-online asiakasnumero:</strong>
                {{ talenomCustomerNumber }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Yhteystiedot</v-card-title>
        <v-container>
          <h4>Vuokralainen</h4>

          <v-row v-if="currentTenant.isCompany" dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-checkbox
                v-model="fetchFromYtj"
                label="Salli YTJ:n hakea tietoja"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentTenant.name"
                :rules="validations.required"
                outlined
                dense
                label="Nimi"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-if="currentTenant.isCompany"
                v-model.trim="currentTenant.businessId"
                label="Y-tunnus"
                outlined
                dense
                :rules="[isRequiredByInvoiceService({ ropoCapital: false, talenom: true })]"
                @input="checkYTJ"
                hide-details
              ></v-text-field>
              <v-text-field
                v-if="!currentTenant.isCompany"
                v-model.trim="currentTenant.social"
                label="Henkilötunnus"
                :rules="socialRules"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentTenant.phone"
                label="Puhelin"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentTenant.email"
                label="Email"
                :rules="validations.email2"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Osoite</h4>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-autocomplete
                v-model="nominatimAddress"
                :disabled="isDisabled('apartment', 'address')"
                :items="foundAddresses"
                :item-text="nominatimHelpers.getAddressListName"
                outlined
                label="Hae osoitteella"
                dense
                return-object
                no-filter
                @change="
                  setAddress($event);
                  clearInput();
                "
                @input.native="getAddresses"
                hide-details
              ></v-autocomplete> </v-col
          ></v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentTenant.address"
                label="Postiosoite"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentTenant.zipCode"
                label="Postinumero"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentTenant.city"
                label="Kaupunki"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-autocomplete
                v-model="currentTenant.country"
                :items="getCountries()"
                outlined
                clearable
                label="Maa"
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>

          <h4 class="mt-1">Lisätiedot</h4>

          <v-row dense>
            <v-col md="6">
              <v-textarea
                v-model="currentTenant.comments"
                placeholder="Muuta tietoa vuokralaisesta..."
                outlined
                dense
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <!-- Invoice -->
      <v-card class="mt-2">
        <v-card-title>Laskutustiedot</v-card-title>
        <v-card-subtitle class="error--text"
          >Jos muutat asiakaskohtaista viitenumeron käyttöä, käy tallentamassa myös luonnoksena
          olevat laskut, johon viitenumeron käyttö voi vaikuttaa.</v-card-subtitle
        >
        <v-container>
          <v-row dense>
            <v-col cols="6" sm="6" md="4" lg="3" xl="2">
              <v-checkbox
                v-model="currentTenant.personalRefNumInUse"
                label="Käytä asiakaskohtaista viitenumeroa"
                hide-details
              ></v-checkbox>
            </v-col>

            <v-col cols="6" sm="6" md="4" lg="3" xl="2">
              <v-select
                v-model="currentTenant.invoiceLanguage"
                item-text="text"
                item-value="val"
                :items="getServiceInvoiceLanguages()"
                outlined
                dense
                label="Laskun kieli"
                hide-details
              ></v-select>
            </v-col>
          </v-row>

          <div v-if="currentTenant.isCompany">
            <h4 class="mt-1">Sähköinen laskutus</h4>
            <v-row dense>
              <v-col cols="6" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="currentTenant.evoice.evoiceAddress"
                  outlined
                  dense
                  label="Verkkolaskuosoite *"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="currentTenant.evoice.evoiceId"
                  outlined
                  dense
                  placeholder="esim. BAWCFI22"
                  label="Operaattoritunnus *"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="currentTenant.evoice.evoiceOperator"
                  outlined
                  dense
                  placeholder="esim. Basware Oyj"
                  label="Välittäjä"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card>

      <!-- Other data -->
      <v-card class="mt-2">
        <v-card-title>Muut tiedot</v-card-title>
        <v-container>
          <h4>Yhteyshenkilö</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentTenant.contactPersonName"
                label="Yhteyshenkilö nimi"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentTenant.contactPersonPhone"
                label="Yhteyshenkilö puhelin"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn color="info" class="mr-4 mt-5" @click="submit">{{ submitBtnText }}</v-btn>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import axios from "axios";
import FullPageLoader from "@/components/FullPageLoader";
import nominatimHelpers from "@/utils/nominatimHelpers";
import { mapState, mapMutations, mapActions } from "vuex";
import { debounce } from "../../utils/helpers";
import validations from "@/validations";

export default {
  mixins: [mixins, invoiceMixins],

  components: {
    FullPageLoader,
  },

  props: {
    submitBtnText: { type: String, default: "" },
  },

  data(vm) {
    return {
      addressInput: "",
      fetchFromYtj: false,
      nominatimHelpers,
      validations,
      socialRules: [(v) => vm.checkFinnishCitizen(v)],
    };
  },

  computed: {
    ...mapState("tenant", ["loading", "currentTenant"]),
    ...mapState("nominatim", ["foundAddresses"]),

    nominatimAddress: {
      get() {
        return this.$store.state.nominatim.nominatimAddress;
      },
      set(val) {
        this.setNominatimAddress(val);
      },
    },

    talenomCustomerNumber() {
      return this.currentTenant?.talenom?.talenomCustNum || "Ei vielä muodostettu";
    },
  },

  watch: {
    "currentTenant.social"() {
      if (this.currentTenant.social) {
        const formattedSocial = this.currentTenant.social.replace(/[a-z]/g, (match) =>
          match.toUpperCase()
        );
        this.setTenantField({
          field: "social",
          val: formattedSocial,
        });
      }
    },

    currentTenant: {
      deep: true,
      handler(val) {
        this.setCurrentValuesToTrackedFields(val);
      },
    },

    fetchFromYtj(val) {
      if (val) this.checkYTJ();
    },

    addressInput: debounce(function (newVal) {
      this.getInfoByAddress(newVal);
    }, 400),
  },

  methods: {
    ...mapMutations("tenant", [
      "setLoading",
      "setTenantField",
      "setCurrentTenant",
      "setCurrentValuesToTrackedFields",
    ]),
    ...mapMutations("nominatim", ["setNominatimAddress"]),
    ...mapActions("nominatim", ["getInfoByAddress"]),

    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit");
      } else {
        this.showPopup("Jotkin kentät sisältävät virheitä. Tarkista kentät.", "error");
      }
    },

    getAddresses(event) {
      this.addressInput = event.target.value;
    },

    setBasicFields() {
      // Not company
      if (!this.currentTenant.isCompany) {
        this.setTenantField({
          field: "businessId",
          val: null,
        });
        this.setTenantField({
          field: "finnishCitizen",
          val: true,
        });
      } else {
        // Company
        this.setTenantField({
          field: "social",
          val: null,
        });
        this.setTenantField({
          field: "finnishCitizen",
          val: false,
        });

        // Fetch new data
        if (this.fetchFromYtj && this.currentTenant.businessId) this.checkYTJ();
      }
    },

    setAddress(data) {
      if (data) {
        const { address, zipCode, city, country } = nominatimHelpers.getAddressData(data);
        this.setTenantField({
          field: "address",
          val: address,
        });
        this.setTenantField({
          field: "zipCode",
          val: zipCode,
        });
        this.setTenantField({
          field: "city",
          val: city,
        });
        this.setTenantField({
          field: "country",
          val: country,
        });
      }
    },

    clearInput() {
      this.$nextTick(() => {
        this.setNominatimAddress(null);
      });
    },

    async checkYTJ() {
      if (this.fetchFromYtj) {
        if (
          this.currentTenant.businessId &&
          this.currentTenant.businessId.length >= 9 &&
          this.currentTenant.businessId.includes("-")
        ) {
          try {
            let res = await axios.get(
              `https://avoindata.prh.fi/bis/v1/${this.currentTenant.businessId}`
            );
            let data = res.data.results[0];

            this.setTenantField({
              field: "name",
              val: data.name,
            });

            let administrator = data.addresses.filter((el) => el.version == 1 && el.street != null);

            if (administrator[0]) {
              this.setTenantField({
                field: "address",
                val: administrator[0].street,
              });
              this.setTenantField({
                field: "zipCode",
                val: administrator[0].postCode,
              });

              let city = String(administrator[0].city).toLowerCase();

              this.setTenantField({
                field: "city",
                val: city.charAt(0).toUpperCase() + city.slice(1),
              });
            }

            let administratorPhone = data.contactDetails.filter(
              (el) => el.version == 1 && el.type == "Matkapuhelin"
            );

            if (administratorPhone[0]) {
              this.setTenantField({
                field: "phone",
                val: administratorPhone[0].value,
              });
            }
          } catch (err) {
            this.showPopup(
              "Kaikkia kenttiä tai osaa kenttien tiedoista ei voitu täyttää.",
              "error"
            );
          }
        }
      }
    },

    checkFinnishCitizen(val) {
      if (this.currentTenant.finnishCitizen) {
        return !!val || "Pakollinen kenttä";
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
