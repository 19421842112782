import { render, staticRenderFns } from "./CreateOldRentalContract.vue?vue&type=template&id=62758563&scoped=true"
import script from "./CreateOldRentalContract.vue?vue&type=script&lang=js"
export * from "./CreateOldRentalContract.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62758563",
  null
  
)

export default component.exports